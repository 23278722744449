import React, { useState } from 'react'

import CreateIcon from '@mui/icons-material/Create'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { Box, Container, Grid, IconButton, Typography } from '@mui/material'

import BreadcrumbComponent from 'components/BreadcrumbMenu/v2'

import useCreateCustomGameModal from 'hooks/useCreateCustomGameModal'

import { GAME_STEP } from 'enums/GameStepEnum'
import { GameType } from 'enums/GameTypeEnum'
import { TopicType } from 'enums/TopicTypeEnum'

import EditModal from './EditModal/EditModal'
import { styles } from './Hero.styles'

export const TEST_ID = 'game-hero'

export type Props = {
	companyProblem: string
	createPrompt: () => void
	gameTypeEnum: GameType
	hideEdit?: boolean
	innovationCompany: string
	innovationTopic?: TopicType
	isLoading: boolean
	setCompanyProblem: (val: string) => void
	setInnovationCompany: (val: string) => void
	setInnovationTopic?: (val: TopicType) => void
	step: GAME_STEP
}

export default function Hero({
	step,
	gameTypeEnum,
	innovationCompany,
	innovationTopic,
	companyProblem,
	isLoading,
	setInnovationCompany,
	setInnovationTopic,
	setCompanyProblem,
	createPrompt,
	hideEdit,
}: Props) {
	const { showCreateCustomGameModal } = useCreateCustomGameModal()

	const [isModalOpen, setIsModalOpen] = useState(false)
	const openModal = React.useCallback(() => {
		setIsModalOpen(true)
	}, [])

	const closeModal = React.useCallback(() => {
		setIsModalOpen(false)
	}, [])

	const gameHeroData = gameTypeEnum
	const heroStyles = styles(gameHeroData)

	const openCustomGameModal = () => {
		showCreateCustomGameModal({
			gameLabel: gameHeroData.label,
			gameState: {
				gameTypeId: gameHeroData.value,
				innovationCompany,
				innovationTopic,
				companyProblem,
			},
		})
	}

	return (
		<>
			<Grid container direction={'column'} sx={heroStyles.mainGrid}>
				<Grid
					item
					xs={2}
					sx={{
						paddingX: {
							xs: '2rem',
							md: '3rem',
						},
					}}
				>
					<BreadcrumbComponent gameTypeEnum={gameTypeEnum} />
				</Grid>
				{step === GAME_STEP.Step1 && (
					<Grid
						container
						item
						spacing={2}
						sx={{
							paddingX: {
								xs: '5rem',
								s: '8rem',
							},
							flexDirection: {
								xs: 'column-reverse',
								md: 'row',
							},
						}}
					>
						<Grid
							container
							item
							md={8}
							sx={{
								pr: '2rem',
							}}
						>
							<Grid
								container
								direction={'column'}
								spacing={4}
								justifyContent={'center'}
							>
								<Grid item>
									<Typography
										variant="h1"
										fontWeight={300}
										color="black"
										sx={{
											opacity: 0.87,
											paddingTop: {
												xs: 0,
											},
											fontSize: {
												xs: '7rem',
												md: '10rem',
											},
										}}
									>
										{gameHeroData.label}
									</Typography>
								</Grid>
								<Grid item container spacing={2}>
									<Grid item>
										<Typography
											fontWeight={500}
											color="black"
											sx={{
												opacity: 0.87,
											}}
										>
											{gameHeroData.subtitle}
										</Typography>
									</Grid>
									<Grid item>
										<Typography
											fontWeight={400}
											color="black"
											sx={{
												opacity: 0.87,
											}}
										>
											{gameHeroData.subtitle2}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid
							container
							item
							xs={7}
							sm={4}
							md={4}
							justifyContent={'center'}
						>
							<img
								src={gameHeroData.image}
								style={heroStyles.heroImg}
							/>
						</Grid>
					</Grid>
				)}
				{step === GAME_STEP.Step2 && (
					<>
						<Grid container direction={'column'}>
							<Container maxWidth="md">
								<Box
									display="flex"
									justifyContent="space-between"
									alignItems="center"
									marginY="1rem"
								>
									<Typography>1. INNOVATION TOPIC</Typography>
									<Box display="flex" marginY="1rem">
										{!hideEdit && (
											<Box
												display="flex"
												height="38px"
												padding="7px 11px 7px 4px"
												align-items="flex-end"
												gap="0"
												marginRight="10px"
											>
												<IconButton
													data-testid={`${TEST_ID}-open-create-custom-modal-button`}
													onClick={
														openCustomGameModal
													}
												>
													<PersonAddIcon />
												</IconButton>
												<Typography
													fontWeight="500"
													onClick={
														openCustomGameModal
													}
													sx={{
														cursor: 'pointer',
													}}
												>
													SHARE GAME
												</Typography>
											</Box>
										)}
										{!hideEdit && (
											<Box
												display={'flex'}
												height="38px"
												alignItems={'center'}
												padding=" 4px 5px"
												justify-content="center"
												gap="0"
											>
												<IconButton
													data-testid={`${TEST_ID}-open-modal-button`}
													onClick={openModal}
												>
													<CreateIcon />
												</IconButton>
												<Typography
													fontWeight="500"
													onClick={openModal}
													sx={{
														cursor: 'pointer',
													}}
												>
													Edit
												</Typography>
											</Box>
										)}
									</Box>
								</Box>
								<Box
									border={1}
									borderRadius={{
										xs: 2,
										sm: 6,
									}}
									display="flex"
									justifyContent="center"
									flexDirection={{
										xs: 'column',
										sm: 'row',
									}}
									gap="1.5rem"
									paddingY={'1rem'}
									paddingX={'2rem'}
								>
									<Typography
										borderRight={{
											xs: 0,
											sm: 1,
										}}
										borderBottom={{
											xs: 1,
											sm: 0,
										}}
										paddingRight={'2rem'}
										paddingBottom={{
											xs: '1rem',
											sm: 0,
										}}
									>
										{innovationCompany}
									</Typography>
									{innovationTopic && (
										<Typography
											borderRight={{
												xs: 0,
												sm: companyProblem ? 1 : 0,
											}}
											borderBottom={{
												xs: companyProblem ? 1 : 0,
												sm: 0,
											}}
											paddingRight={'2rem'}
											paddingBottom={{
												xs: '1rem',
												sm: 0,
											}}
										>
											{innovationTopic}
										</Typography>
									)}
									{companyProblem && (
										<Typography
											sx={{
												wordBreak: 'break-word',
											}}
										>
											{companyProblem}
										</Typography>
									)}
								</Box>
							</Container>
						</Grid>
						{gameHeroData.modalText && (
							<EditModal
								open={isModalOpen}
								closeModal={closeModal}
								innovationCompany={innovationCompany}
								innovationTopic={innovationTopic}
								companyProblem={companyProblem}
								modalText={gameHeroData.modalText}
								setInnovationCompany={setInnovationCompany}
								setInnovationTopic={setInnovationTopic}
								setCompanyProblem={setCompanyProblem}
								isLoading={isLoading}
								createPrompt={createPrompt}
								editable={!hideEdit || true}
							/>
						)}
					</>
				)}
			</Grid>
		</>
	)
}
