import React from 'react'

import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { BaseTextField } from 'components/Form'
import TopicChips from 'components/Hero/TopicChips'

import { TopicType } from 'enums/TopicTypeEnum'

import { styles } from './Section1.styles'

export const TEST_ID = 'teeter-totter-section1'

export type Props = {
	companyProblem: string
	createPrompt: () => void
	editable: boolean
	generatePromptsDisabled: () => boolean
	innovationCompany: string
	innovationTopic: TopicType
	isLoading: boolean
	resetAllField: () => void
	setCompanyProblem: (val: string) => void
	setInnovationCompany: (val: string) => void
	setInnovationTopic: (val: TopicType) => void
}

const Section1 = ({
	innovationCompany,
	setInnovationCompany,
	companyProblem,
	setCompanyProblem,
	innovationTopic,
	setInnovationTopic,
	generatePromptsDisabled,
	resetAllField,
	isLoading,
	createPrompt,
	editable,
}: Props) => {
	return (
		<>
			<Typography marginY={'2rem'} variant={'overline'}>
				1. INNOVATION TOPIC
			</Typography>
			<Box display={'flex'}>
				<Box marginRight={'2rem'} sx={styles.sideBar} />
				<Box
					display={'flex'}
					flexDirection={'column'}
					rowGap={'3rem'}
					flexGrow="1"
				>
					<Typography color="black">
						What company are we brainstorming about today?
					</Typography>
					<BaseTextField
						InputLabelProps={{ style: styles.inputLabel }}
						variant="outlined"
						data-testid={`${TEST_ID}__company-name-input`}
						label="Enter company name here."
						value={innovationCompany}
						onChangeValue={(val) => setInnovationCompany(val)}
						required
						size="medium"
						sx={{
							'& .MuiInputBase-input': {
								boxSizing: 'content-box',
								height: '100%',
							},
							...styles.textField,
						}}
					/>
					<Typography color="black">
						What business topic do you want to focus on?
					</Typography>
					<TopicChips
						innovationTopic={innovationTopic}
						setInnovationTopic={setInnovationTopic}
						editable={editable}
						color="#FFA1BD"
					/>
					<Typography color="black" sx={{ opacity: '87%' }}>
						Tell us briefly about a business problem you want to
						solve (within 5-10 words).
					</Typography>
					<BaseTextField
						InputLabelProps={{
							style: styles.inputLabel,
						}}
						variant="outlined"
						data-testid={`${TEST_ID}__company-problem-input`}
						label="Optional - Enter a business problem here."
						onChangeValue={(val) => setCompanyProblem(val)}
						value={companyProblem}
						size="medium"
						sx={{
							'& .MuiInputBase-input': {
								boxSizing: 'content-box',
								height: '100%',
							},
							...styles.textField,
						}}
					/>
					<Box sx={styles.buttonsContainer}>
						<Button
							color="secondary"
							variant="contained"
							data-testid={`${TEST_ID}__create-prompt-button`}
							disabled={generatePromptsDisabled() || isLoading}
							onClick={createPrompt}
						>
							CREATE A PROMPT
						</Button>
						<Button
							variant="text"
							color="secondary"
							data-testid={`${TEST_ID}__reset-all-button`}
							onClick={resetAllField}
						>
							RESET ALL FIELDS
						</Button>
					</Box>
				</Box>
			</Box>
		</>
	)
}
export default Section1
