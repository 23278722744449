import React, { useEffect, useState } from 'react'

import { Box, Grid, SelectChangeEvent, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

import { BattleShipModel, Conversation } from 'models/BattleShipModel'
import { Game } from 'models/gameModels'

import { useToastContext } from 'contexts/ToastProvider'

import {
	postBattleshipCompetitorResponse,
	postBattleshipPrompt,
	postBattleshipResponse,
	postBattleshipResponseToCompetitor,
} from 'services/completions.service'
import { postIdea } from 'services/ideas.service'

import BreadcrumbComponent from 'components/BreadcrumbMenu'
import ButtonWithIconComponent from 'components/ButtonWithIcon'
import { BaseDropdown, BaseTextField } from 'components/Form'
import RichTextField from 'components/Form/RichTextField/RichTextField'
import PromptBox from 'components/PromptBox/PromptBox'
import RectangleButtonComponent from 'components/RectangleButton'
import SectionHeader from 'components/SectionHeader'
import SubmitIdeasButton from 'components/SubmitIdeasButton'

import { defaultErrorMessage } from 'assets/alertText'
import { brainstormText } from 'assets/brainstormText'
import { GenAiPromptText } from 'assets/images/GenAiPromptText'
import refreshIcon from 'assets/images/refreshIcon.svg'

import { GAME_TYPE } from 'enums/GameTypeEnum'
import { ToastSeverity } from 'enums/ToastSeverityEnum'

import { styles } from './BattleShips.styles'

export const TEST_ID = 'battle-ships'

const userMessage = {
	content: '',
	role: 'user',
}

const updateAIResponse = (content: string) => {
	return {
		content,
		role: 'assistant',
	}
}

function BattleShips({ game }: { game?: Game }) {
	const { showAlert } = useToastContext()

	const [isLoading, setIsLoading] = useState(false)

	const [isAiLoading, setIsAiLoading] = useState([false, false, false])

	const [aiExampleVisible, setAiExampleVisible] = useState<
		Record<number, boolean>
	>({})

	useEffect(() => {
		if (game) {
			setCompanyName(game.innovationCompany)
			setTopic(game.innovationTopic)
			setGameId(game.gameId)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [game])

	const {
		companyName,
		setCompanyName,
		topic,
		setTopic,
		promptConversation,
		setPromptConversation,
		currentPrompt,
		setCurrentPrompt,
		responseConversation,
		setResponseConversation,
		currentResponses,
		setCurrentResponses,
		authorIdeation,
		setAuthorIdeation,
		generateScenarioDisabled,
		generateNewScenarioDisabled,
		promptObject,
		resetSoft,
		requestObject,
		postObject,
		submitIdeasDisabled,
		generateAiDisabled,
		reset,
		setGameId,
		generateExampleDisabled,
	} = BattleShipModel()

	const preFetchAiResponses = async (companyDisruption: string) => {
		let aiScenarioResponse = ''
		const props = {
			companyDisruption: '',
			aiScenarioResponse: '',
		}

		for (let i = 0; i < currentResponses.length; i++) {
			props.companyDisruption = companyDisruption
			if (i === 2) {
				props.aiScenarioResponse = aiScenarioResponse
			}
			const response = await handleAiResponseClick(i, false, false)
			if (i === 1) {
				aiScenarioResponse = response
			}
		}
	}

	const handleClick = async () => {
		setIsLoading(true)
		setAiExampleVisible({})
		resetSoft()

		try {
			// setup the conversation
			const prompt = promptObject()
			prompt.conversation = [...promptConversation, userMessage]

			const response = await postBattleshipPrompt({
				innovationTopic: prompt.topic,
				innovationCompany: prompt.companyName,
				conversation: prompt.conversation,
			})

			if (
				response.status === 200 &&
				response.data &&
				response.data.companyDisruption
			) {
				preFetchAiResponses(response.data.companyDisruption)
				const msg = JSON.stringify(response.data)
				// add response to state
				setPromptConversation((prevMessages) => [
					...prevMessages,
					userMessage,
					updateAIResponse(msg),
				])

				// setup the page correctly
				setCurrentPrompt(response.data.companyDisruption)
			}
		} catch (error) {
			console.error(error)
			if (showAlert) {
				showAlert(defaultErrorMessage)
			}
		} finally {
			setIsLoading(false)
		}
	}

	const handleChange = (i: number) => (val: string) => {
		setAuthorIdeation((oldAuthorIdeation) => {
			return oldAuthorIdeation.map((cur, idx) => {
				if (i === idx) {
					return val
				} else {
					return cur
				}
			})
		})
	}

	const handleAiResponseClick = async (
		i: number,
		isUserInitiated = true,
		showToast = true
	): Promise<string> => {
		let preFetchReturn = ''
		if (
			isUserInitiated &&
			i > 0 &&
			(!currentResponses[i - 1] || !aiExampleVisible[i - 1])
		)
			return ''
		if (isUserInitiated && !aiExampleVisible[i]) {
			setAiExampleVisible({ ...aiExampleVisible, [i]: true })
			return ''
		}
		// set is loading
		setIsAiLoading((oldIsAiLoading) => {
			// we need _cur as a placeholder, but it is unused.  use an _ to ignore.
			return oldIsAiLoading.map((_cur, idx) => {
				if (i === idx) {
					return true
				} else {
					return false
				}
			})
		})

		try {
			// setup the conversation
			const prompt = requestObject(i)
			if (!isUserInitiated) {
				prompt.conversation = [userMessage]
			} else {
				prompt.conversation = [...responseConversation[i], userMessage]
			}

			let response = {
				status: 400,
				data: {
					competitorName: '',
					responses: [''],
				},
			}

			if (i === 0) {
				response = await postBattleshipResponse({
					innovationTopic: prompt.topic,
					innovationCompany: prompt.companyName,
					companyDisruption: prompt.companyDisruption,
					conversation: prompt.conversation,
				})
			} else if (i === 1) {
				response = await postBattleshipCompetitorResponse({
					innovationCompany: prompt.companyName,
					companyDisruption: prompt.companyDisruption,
					conversation: prompt.conversation,
				})
			} else if (i === 2) {
				response = await postBattleshipResponseToCompetitor({
					aiScenarioResponse: prompt.aiScenarioResponse,
					innovationCompany: prompt.companyName,
					companyDisruption: prompt.companyDisruption,
					conversation: prompt.conversation,
				})
			}

			if (
				response.status === 200 &&
				response.data &&
				response.data.responses
			) {
				const responsesArr = response.data.responses
				preFetchReturn = responsesArr.join(' ') || ''
				const msg = JSON.stringify(response.data)

				// add response to state
				let entireResponse: Conversation[] = []

				if (!isUserInitiated) {
					entireResponse = [userMessage, updateAIResponse(msg)]
				} else {
					entireResponse = [
						...responseConversation[i],
						userMessage,
						updateAIResponse(msg),
					]
				}

				// store the entire conversation
				setResponseConversation((oldResponseConversation) => {
					return oldResponseConversation.map((cur, idx) => {
						if (i === idx) {
							return entireResponse
						} else {
							return cur
						}
					})
				})

				// update the current response
				setCurrentResponses((oldCurrentResponses) => {
					return oldCurrentResponses.map((cur, idx) => {
						if (i === idx) {
							return responsesArr
						} else {
							return cur
						}
					})
				})
			} else {
				throw Error(
					`response received ${JSON.stringify(response.data)}`
				)
			}
		} catch (error) {
			console.error(error)
			if (showToast && showAlert) {
				showAlert(defaultErrorMessage)
			}
		} finally {
			setIsAiLoading((oldIsAiLoading) => {
				return oldIsAiLoading.map(() => {
					return false
				})
			})
		}

		return preFetchReturn
	}

	const handleSubmitIdeas = async () => {
		setIsLoading(true)

		try {
			await postIdea(postObject())
			if (showAlert) {
				showAlert({ severity: ToastSeverity.SUCCESS })
			}
		} catch (error) {
			console.error(error)
			if (showAlert) {
				showAlert(defaultErrorMessage)
			}
		} finally {
			setIsLoading(false)

			// need to reset the model
			reset()

			window.scrollTo({
				top: 0,
			})
			await handleClick()
		}
	}

	const buildResponseItem = (solution: string[]) => {
		return (
			<ol data-testid="gen-ai-sub-response-list">
				{solution.map((sol: string, idx: number) => (
					<React.Fragment key={idx}>
						<Typography
							component="li"
							key={`ai-sub-solution-${idx}`}
							sx={styles.solutionText}
							variant="body1"
						>
							{sol}
						</Typography>
					</React.Fragment>
				))}
			</ol>
		)
	}

	const handleCompanyNameChange = (value: string) => {
		setCompanyName(value)
		reset()
	}

	const handleTopicChange = async (
		// have to allow unknown since mui allows for this
		event: SelectChangeEvent<string | number | unknown>
	) => {
		setTopic(event.target.value as string)
		reset()
	}

	return (
		<Box>
			<BreadcrumbComponent
				gameDescription="for proactively competing"
				gameTypeEnum={GAME_TYPE.BATTLE_SHIPS}
				game={game}
			/>
			<Box data-testid="container" sx={styles.container}>
				<Grid container sx={styles.containerLayout}>
					<Grid
						data-testid={`${TEST_ID}-content-container`}
						item
						sx={styles.section}
					>
						<Box data-testid={`${TEST_ID}__header-1`}>
							<SectionHeader
								number={'1'}
								title={'Innovation Topic'}
							>
								{brainstormText.battleShips.ideationOne}
							</SectionHeader>
						</Box>
						<Box sx={styles.dropdownContainer}>
							<BaseDropdown
								data-testid="scenario-type-dropdown"
								label={brainstormText.battleShips.dropdownLabel}
								options={
									brainstormText.battleShips.dropdownValues
								}
								value={game?.innovationTopic || topic}
								onChange={handleTopicChange}
								readOnly={!!game}
							/>
							<Typography sx={styles.sectionText} variant="body2">
								for
							</Typography>
							<Box component="form" sx={{ width: '42rem' }}>
								<BaseTextField
									onChangeValue={handleCompanyNameChange}
									data-testid={`${TEST_ID}__topic-input-field`}
									label="Enter Company Name Here"
									value={
										game?.innovationCompany || companyName
									}
									readOnly={!!game}
									required
								/>
							</Box>
							<Box sx={{ pl: '2.2rem' }}>
								<RectangleButtonComponent
									disabled={
										generateScenarioDisabled() || isLoading
									}
									data-testid={`${TEST_ID}__topic-button`}
									text={'Generate Scenario'}
									color="secondary"
									onClickAction={handleClick}
								/>
							</Box>
						</Box>
					</Grid>
					<Grid
						data-testid={`${TEST_ID}-scenario-container`}
						item
						sx={styles.section}
					>
						<Box data-testid={`${TEST_ID}__header-2`}>
							<SectionHeader number={'2'} title={'Scenario'}>
								{brainstormText.battleShips.ideationTwo}
							</SectionHeader>
						</Box>
						<Box sx={styles.scenarioContainer}>
							<Box sx={styles.promptContainer}>
								{/** Fix auto width from button */}
								<Box sx={{ width: '14rem' }}>
									<ButtonWithIconComponent
										disabled={
											generateNewScenarioDisabled() ||
											isLoading
										}
										iconSvg={refreshIcon}
										text="New Scenario"
										data-testid={`${TEST_ID}__prompt-box_main-refresh`}
										onClickAction={handleClick}
									/>
								</Box>
								<PromptBox
									contentText={
										currentPrompt ||
										'Generate Scenario Above'
									}
									data-testid="company-disruption"
									headerText="Company Disruption"
									loading={isLoading}
								/>
							</Box>
						</Box>
					</Grid>
					<Grid
						data-testid={`${TEST_ID}-ideation-container`}
						item
						sx={styles.section}
					>
						<Box data-testid={`${TEST_ID}__header-3`}>
							<SectionHeader number={'3'} title={'Ideation'}>
								{brainstormText.battleShips.ideationThree}
							</SectionHeader>
						</Box>
						<Grid
							container
							data-testid={`${TEST_ID}-ideation-response-container`}
							sx={styles.ideationResponseContainer}
						>
							{currentResponses.map(
								(
									_d, // we need the item '_d' here even if it is not used.
									i
								) => (
									<Grid
										alignItems="start"
										container
										direction={'column'}
										item
										key={`${TEST_ID}__prompt-box_${i}`}
										data-testid={`${TEST_ID}__prompt-box_${i}`}
										xs={3}
									>
										<Typography
											sx={styles.ideationResponseTitle}
										>
											{
												brainstormText.battleShips
													.ideationTitles[i]
											}
										</Typography>

										<RichTextField
											onChange={handleChange(i)}
											placeholderText={
												brainstormText.battleShips
													.ideationPlaceholders[i]
											}
											value={authorIdeation[i] ?? ''}
											fillheight={false}
										/>
										<ButtonWithIconComponent
											disabled={
												generateAiDisabled() ||
												isLoading ||
												isAiLoading.some(
													(loading) => loading
												)
											}
											iconSvg={refreshIcon}
											text="New AI Response"
											data-testid={`${TEST_ID}__prompt-box_${i}-button`}
											onClickAction={() =>
												handleAiResponseClick(i)
											}
										/>
										<Box
											data-testid={`gen-ai-response-container-${i}`}
											sx={styles.genAiResponseContainer}
										>
											{isAiLoading[i] &&
											aiExampleVisible[i] ? (
												<Box
													data-testid={`${TEST_ID}__generate-example-loading`}
													sx={
														styles.genAiUnSelectableContent
													}
												>
													<CircularProgress color="secondary" />
												</Box>
											) : (
												<>
													{!currentResponses[i] ||
													!aiExampleVisible[i] ? (
														<Box
															data-testid={`gen-ai-preview-container-${i}`}
															component={'button'}
															sx={
																generateExampleDisabled(
																	i
																)
																	? styles.genAiPreviewContentDisabled
																	: styles.genAiPreviewContent
															}
															onClick={() =>
																handleAiResponseClick(
																	i
																)
															}
														>
															<Box
																data-testid={`${TEST_ID}__generate-example-prompt_${i}`}
																sx={
																	styles.genAiPreviewImg
																}
															>
																<GenAiPromptText
																	disabled={generateExampleDisabled(
																		i
																	)}
																/>
																<Typography
																	sx={
																		generateExampleDisabled(
																			i
																		)
																			? styles.genAiPreviewTextDisabled
																			: styles.genAiPreviewText
																	}
																	variant="body1"
																>
																	Generate
																	example
																	scenario
																	response
																</Typography>
															</Box>
														</Box>
													) : (
														<Box
															data-testid="gen-ai-preview-content"
															sx={
																styles.genAiUnSelectableContent
															}
														>
															<Box
																sx={
																	styles.genAiResponseContent
																}
															>
																{buildResponseItem(
																	currentResponses[
																		i
																	]
																)}
															</Box>
														</Box>
													)}
												</>
											)}
										</Box>
									</Grid>
								)
							)}
						</Grid>
					</Grid>
					<Grid data-testid={`${TEST_ID}-submit-container`} item>
						<Box sx={styles.submitContainer}>
							<Box>
								<SubmitIdeasButton
									disabled={
										submitIdeasDisabled() ||
										isLoading ||
										isAiLoading.some((loading) => loading)
									}
									handleSubmit={handleSubmitIdeas}
									sx={styles.submitButton}
									isCustomGame={!!game}
									gameTypeId={GAME_TYPE.BATTLE_SHIPS.value}
									isSubmitButtonLoading={isLoading}
								/>
							</Box>
							<Box>
								<Typography
									sx={styles.sectionText}
									variant="body2"
								>
									{brainstormText.battleShips.submittedIdeas}
								</Typography>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Box>
	)
}

export default BattleShips
