const styles = {
	textField: {
		'& .MuiOutlinedInput-root': {
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'black',
				opacity: '23%',
			},
		},
	},
	inputLabel: {
		color: 'black',
		opacity: '23%',
	},
	linkModal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '80%',
		maxWidth: '822px',
		bgcolor: 'background.paper',
		border: '2px transparent',
		borderRadius: '30px',
		boxShadow: 24,
		p: 4,
	},
}

export { styles }
