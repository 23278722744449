import React, { useCallback, useState } from 'react'

import { Box, Button, IconButton, Typography } from '@mui/material'

import { MODAL_TYPES, useModalContext } from 'contexts/ModalProvider'

import { BaseTextField } from 'components/Form'
import IconEditPencilOutline from 'components/Icon/IconEditPencilOutline'

import useAuth from 'hooks/useAuth'

import Avatar from 'assets/images/Avatar.svg'
import profileBannerImage from 'assets/images/ProfilePage/profileBannerImage.svg'

import { styles } from './Profile.styles'

const TEST_ID = 'profile-page'

const ProfilePage = () => {
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [email, setEmail] = useState('')
	const [disableSaveButton, setDisableSaveButton] = useState(true)
	const { user } = useAuth()
	const { showModal } = useModalContext()

	const changeProfileImage = () => {}

	const saveChanges = () => {
		console.log('saveChanges')
	}

	const changePassword = useCallback(() => {
		showModal(MODAL_TYPES.FORGOT_PASSWORD_MODAL)
	}, [showModal])

	const deleteAccount = useCallback(() => {
		showModal(MODAL_TYPES.DELETE_ACCOUNT_MODAL)
	}, [showModal])

	return (
		<Box>
			<Box
				data-testid={`${TEST_ID}__banner-section`}
				sx={styles.bannerSection}
			>
				<Box
					component="img"
					alt="Profile Banner Image"
					data-testid={`${TEST_ID}__profile-banner-image`}
					src={profileBannerImage as unknown as string}
				/>

				<Box sx={styles.headerContainer}>
					<Typography sx={styles.header}>My Profile</Typography>
					<Typography sx={styles.subheader}>
						View and modify your personal details, account settings,
						and notification preferences.
					</Typography>
				</Box>
			</Box>
			<Box
				data-testid={`${TEST_ID}__profile-image-section`}
				sx={styles.currentProfileSection}
			>
				<Box sx={styles.profileContainer}>
					<Box sx={styles.profileImageContainer}>
						<Box
							component="img"
							alt="Avatar Image"
							data-testid={`${TEST_ID}-avatar`}
							src={Avatar as unknown as string}
							onClick={changeProfileImage}
							sx={styles.profileImage}
						/>
						<IconButton sx={styles.editIcon}>
							<IconEditPencilOutline size={18} />
						</IconButton>
					</Box>

					<Typography sx={styles.currentNameLabel}>
						{user ? user.name : ''}
					</Typography>
					<Typography sx={styles.currentEmailLabel}>
						{user ? user.email : ''}
					</Typography>
				</Box>
			</Box>
			<Box
				data-testid={`${TEST_ID}__profile-input-section`}
				sx={styles.profileInputSection}
			>
				<Box marginRight="2rem" sx={styles.sideBar} />
				<Box sx={styles.formContainer}>
					<Typography sx={styles.overline}>BASIC DETAILS</Typography>

					<Box sx={styles.nameInputContainer}>
						<Box sx={styles.nameInputRow}>
							<Typography sx={styles.label}>
								First Name
							</Typography>
							<BaseTextField
								InputLabelProps={{ style: styles.inputLabel }}
								variant="outlined"
								data-testid={`${TEST_ID}__first-name-input`}
								value={firstName}
								onChangeValue={(val) => {
									setFirstName(val)
									setDisableSaveButton(false)
								}}
								required
								size="medium"
								sx={styles.textField}
							/>
						</Box>

						<Box sx={styles.nameInputRow}>
							<Typography sx={styles.label}>Last Name</Typography>
							<BaseTextField
								InputLabelProps={{ style: styles.inputLabel }}
								variant="outlined"
								data-testid={`${TEST_ID}__last-name-input`}
								value={lastName}
								onChangeValue={(val) => {
									setLastName(val)
									setDisableSaveButton(false)
								}}
								required
								size="medium"
								sx={styles.textField}
							/>
						</Box>
					</Box>
					<Typography sx={styles.label}>Email</Typography>

					<BaseTextField
						InputLabelProps={{ style: styles.inputLabel }}
						variant="outlined"
						data-testid={`${TEST_ID}__email-input`}
						onChangeValue={(val) => {
							setEmail(val)
							setDisableSaveButton(false)
						}}
						value={email}
						size="medium"
						sx={styles.textField}
					/>
					<Button
						color="secondary"
						variant="contained"
						data-testid={`${TEST_ID}__save-changes-button`}
						disabled={disableSaveButton}
						onClick={saveChanges}
						sx={styles.button}
					>
						SAVE CHANGES
					</Button>

					<Box sx={styles.buttonsContainer}>
						<Typography sx={styles.overline}>ACCOUNT</Typography>

						<Typography sx={styles.label}>Password</Typography>

						<Typography sx={styles.caption}>
							Change your brainstorm account password.
						</Typography>
						<Button
							color="secondary"
							variant="contained"
							data-testid={`${TEST_ID}__change-password-button`}
							onClick={changePassword}
							sx={styles.button}
						>
							CHANGE PASSWORD
						</Button>

						<Typography sx={styles.label}>Delete</Typography>
						<Typography sx={styles.caption}>
							Deleting your brainstorm account will permanently
							delete all public and private information associated
							with your profile.
						</Typography>
						<Button
							color="error"
							variant="contained"
							data-testid={`${TEST_ID}__delete-account-button`}
							onClick={deleteAccount}
							sx={styles.button}
						>
							DELETE ACCOUNT
						</Button>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export default ProfilePage
