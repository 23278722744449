import axios, { AxiosResponse } from 'axios'

import {
	Idea,
	Ideas,
	Lookups,
	PostIdea,
	PostIdeaResponse,
	RandomPrompts,
	RandomPromptsResponse,
	RandomPromptsTeeterTotter,
	RandomPromptsTeeterTotterResponse,
} from '../models/ideaModels'
import { getSignedRequest } from '../utils/authUtils'

export const postIdea = async (data: PostIdea): Promise<PostIdeaResponse> => {
	const signed = await getSignedRequest('POST', 'ideas', JSON.stringify(data))

	if (signed) {
		return await axios({
			...signed,
			url: (process.env.REACT_APP_AWS_API_URL as string) + 'ideas',
			data,
			method: 'POST',
		})
	} else {
		throw Error('Unable to get signed request')
	}
}

export const getIdeas = async (
	queryObj = {}
): Promise<AxiosResponse<Ideas>> => {
	const signed = await getSignedRequest(
		'POST',
		'ideas/search',
		JSON.stringify(queryObj)
	)

	if (signed) {
		return await axios({
			...signed,
			url: (process.env.REACT_APP_AWS_API_URL as string) + 'ideas/search',
			data: queryObj,
			method: 'POST',
		})
	} else {
		throw Error('Unable to get signed request')
	}
}

export const editIdea = async (data: Idea): Promise<AxiosResponse<Idea>> => {
	const signed = await getSignedRequest(
		'PUT',
		`ideas/${data.submittedId}`,
		JSON.stringify(data)
	)

	if (signed) {
		return await axios({
			...signed,
			url:
				(process.env.REACT_APP_AWS_API_URL as string) +
				`ideas/${data.submittedId}`,
			data,
			method: 'PUT',
		})
	} else {
		throw Error('Unable to get signed request')
	}
}

export const deleteIdea = async (ideaId: string): Promise<AxiosResponse> => {
	const signed = await getSignedRequest('DELETE', `ideas/${ideaId}`)

	if (signed) {
		return await axios({
			...signed,
			url:
				(process.env.REACT_APP_AWS_API_URL as string) +
				`ideas/${ideaId}`,
			method: 'DELETE',
		})
	} else {
		throw Error('Unable to get signed request')
	}
}

export const getInitialIdeas = async (): Promise<AxiosResponse<Lookups>> => {
	const signed = await getSignedRequest('GET', 'lookups/ideas')

	if (signed) {
		return await axios({
			...signed,
			url:
				(process.env.REACT_APP_AWS_API_URL as string) + 'lookups/ideas',
			method: 'GET',
		})
	} else {
		throw Error('Unable to get signed request')
	}
}

export const getRandomPrompts = async (
	data: RandomPrompts
): Promise<RandomPromptsResponse> => {
	const signed = await getSignedRequest(
		'POST',
		'randomprompts',
		JSON.stringify(data)
	)

	if (signed) {
		return await axios({
			...signed,
			url:
				(process.env.REACT_APP_AWS_API_URL as string) + 'randomprompts',
			data,
			method: 'POST',
		})
	} else {
		throw Error('Unable to get signed request')
	}
}

export const getTeeterTotterRandomPrompts = async (
	data: RandomPromptsTeeterTotter
): Promise<RandomPromptsTeeterTotterResponse> => {
	console.log(data)
	const signed = await getSignedRequest(
		'POST',
		'randomprompts/teeterTotter',
		JSON.stringify(data)
	)

	if (signed) {
		return await axios({
			...signed,
			url:
				(process.env.REACT_APP_AWS_API_URL as string) +
				'randomprompts/teeterTotter',
			data,
			method: 'POST',
		})
	} else {
		throw Error('Unable to get signed request')
	}
}
