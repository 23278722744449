import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import CloseSharpIcon from '@mui/icons-material/CloseSharp'
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment'
import MenuIcon from '@mui/icons-material/Menu'
import {
	AppBar,
	Box,
	Container,
	Divider,
	Drawer,
	Grid,
	IconButton,
	Link,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Menu,
	MenuItem,
	Theme,
	Toolbar,
	useTheme,
} from '@mui/material'
import Typography from '@mui/material/Typography'

import { useIdeaStreakContext } from 'contexts/IdeaStreakProvider'
import { useMixpanelContext } from 'contexts/MixpanelProvider'
import { MODAL_TYPES, useModalContext } from 'contexts/ModalProvider'

import useAuth from 'hooks/useAuth'

import { LOCAL_STORAGE_KEYS } from 'assets/constants'
import Avatar from 'assets/images/Avatar.svg'
import newLogo from 'assets/images/newLogo_v2.svg'
import {
	CUSTOM_GAMES,
	HOME_PAGE,
	PROFILE_PAGE,
	SUBMITTED_IDEAS,
} from 'assets/routes'

import { styles } from './ArcNavBar.styles'

export const TEST_ID = 'arc-navbar'

const generateSiteLinks = (
	theme: Theme,
	games: Array<string>,
	pathname: string,
	handleProtectedPathClick: (path: string) => void,
	isMobile: boolean
) => {
	return games.map((game) => {
		const isCurrentPage =
			(pathname.startsWith('/games/') && game === '/') ||
			pathname === game
				? 'current-page'
				: ''

		const gameText = game.replace(/[^a-zA-Z]/g, ' ').toLowerCase() // converts route to readable text

		return (
			<ListItem
				key={game}
				sx={
					isCurrentPage && !isMobile
						? {
								borderBottom: `2px solid ${theme.palette.secondary.main}`,
							}
						: {}
				}
				disableGutters
			>
				<ListItemButton>
					<Link
						data-testid={`${gameText.trim().replace(' ', '-').toLocaleLowerCase()}-link${
							isMobile ? '--mobile' : ''
						}`}
						onClick={() => handleProtectedPathClick(game)}
						sx={{ textDecoration: 'none' }}
					>
						<ListItemText
							className={isCurrentPage}
							primary={game === '/' ? 'all games' : gameText}
							sx={
								isCurrentPage && !isMobile
									? styles.activeNavItemText
									: styles.inactiveNavItemText
							}
						/>
					</Link>
				</ListItemButton>
			</ListItem>
		)
	})
}

const getGivenNameSalutations = (name: string) => {
	if (name && name !== 'login') {
		return `welcome back, ${name}!`
	}
	return name
}

const loginOrSalutationsButton = (
	authorized: boolean,
	name: string,
	clickAction: () => void,
	isMobile: boolean,
	handleShowUserOptions: (event: React.MouseEvent<HTMLElement>) => void
) => (
	<ListItem
		key="drawer-nav-login-or-account"
		disablePadding
		onClick={(event) => {
			!authorized && clickAction()
			authorized && handleShowUserOptions(event)
		}}
		aria-controls={'basic-menu'}
		aria-haspopup="true"
		aria-expanded={'true'}
		sx={styles.loginOrSalutations}
	>
		<ListItemButton
			disableGutters
			data-testid={`${TEST_ID}-login-button${isMobile ? '--mobile' : ''}`}
		>
			<Typography sx={styles.inactiveNavItemText} variant="body1">
				{getGivenNameSalutations(name)}
			</Typography>
		</ListItemButton>
	</ListItem>
)

function ArcNavBar() {
	const { pathname } = useLocation()
	const { isAuthenticated, user, userSignOut } = useAuth()
	const { showModal } = useModalContext()
	const { ideaStreak } = useIdeaStreakContext()
	const [mobileOpen, setMobileOpen] = useState(false)
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const navigate = useNavigate()
	const mixpanel = useMixpanelContext()
	const theme = useTheme()

	const games = [HOME_PAGE, SUBMITTED_IDEAS, CUSTOM_GAMES]

	const openLoginModal = useCallback(() => {
		showModal(MODAL_TYPES.LOGIN_MODAL)
	}, [showModal])

	const handleDrawerToggle = () => {
		setMobileOpen((prevState) => !prevState)
	}

	const handleShowUserOptions = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleRedirectToProfileClick = () => {
		if (isAuthenticated) {
			window.scrollTo(0, 0)
			handleProtectedPathClick(PROFILE_PAGE)
		}
	}

	const handleProtectedPathClick = (path = '') => {
		if (!isAuthenticated) {
			openLoginModal()
			window.localStorage.setItem(LOCAL_STORAGE_KEYS.REDIRECT_PATH, path)
			return
		}
		window.scrollTo(0, 0)
		navigate(path)
	}

	useEffect(() => {
		mixpanel?.track_pageview()
	}, [pathname, mixpanel])

	useEffect(() => {
		const isGuestUser = !user?.session?.idToken?.payload?.given_name
		if (!user?.session) {
			//	logged out user, reset identity
			// 	Reference: https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#call-reset-at-logout
			mixpanel?.reset()
		} else if (!isGuestUser && user?.session?.accessToken?.payload?.sub) {
			// 	Reference: https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#identify
			mixpanel?.identify(String(user?.session?.accessToken?.payload?.sub))
		}
	}, [user, mixpanel])

	const handleLogout = async () => {
		await userSignOut()
		handleClose()
		window.location.assign(HOME_PAGE)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	return (
		<Grid container sx={styles.NavBarGridContainer}>
			<AppBar position="static" sx={styles.AppBar}>
				<Container maxWidth={false} disableGutters>
					<Toolbar
						sx={{
							height: '6.6rem',
						}}
					>
						<Box paddingX="2rem">
							<Box display="inline-block">
								<Link
									color="inherit"
									data-testid="homeButton"
									onClick={() =>
										handleProtectedPathClick(HOME_PAGE)
									}
									sx={{
										display: { xs: 'flex' },
										mr: 1,
										cursor: 'pointer',
									}}
								>
									<img
										alt="new-logo"
										data-testid={`${TEST_ID}-logo`}
										src={newLogo as unknown as string}
									/>
								</Link>
							</Box>
						</Box>
						<Box
							sx={{
								flexGrow: 0,
								display: { xs: 'none', lg: 'flex' },
							}}
						>
							<List sx={styles.navList}>
								{generateSiteLinks(
									theme,
									games,
									pathname,
									handleProtectedPathClick,
									false
								)}
							</List>
						</Box>
						<Box
							flexGrow="1"
							display={{ xs: 'none', lg: 'flex' }}
							justifyContent="flex-end"
							alignItems="center"
							data-testid={`${TEST_ID}-login-box`}
						>
							{isAuthenticated && (
								<Box
									display={{ xs: 'none', lg: 'flex' }}
									alignItems="center"
									sx={styles.IdeaStreakContainer}
									data-testid={`${TEST_ID}-idea-streak-box`}
								>
									<Typography>Idea Streak</Typography>
									<Box>
										<LocalFireDepartmentIcon />
										<Typography> {ideaStreak}</Typography>
									</Box>
								</Box>
							)}
							<List sx={styles.navList}>
								{loginOrSalutationsButton(
									isAuthenticated,
									user?.name ?? '',
									openLoginModal,
									false,
									handleShowUserOptions
								)}
								<Menu
									id="logout-popper"
									anchorEl={anchorEl}
									open={Boolean(anchorEl)}
									onClose={handleClose}
									MenuListProps={{
										'aria-labelledby': 'logout-popper',
									}}
									sx={{
										display: { lg: 'block', md: 'none' },
									}}
								>
									<MenuItem
										onClick={() => {
											handleClose()
											handleRedirectToProfileClick()
										}}
										data-testid={`${TEST_ID}-profile`}
									>
										Profile
									</MenuItem>
									<MenuItem
										onClick={handleLogout}
										sx={styles.logoutButton}
										data-testid={`${TEST_ID}-handle-logout`}
									>
										Logout
									</MenuItem>
								</Menu>
							</List>
							<img
								alt="Avatar"
								data-testid={`${TEST_ID}-avatar`}
								src={Avatar as unknown as string}
								onClick={handleRedirectToProfileClick}
							/>
						</Box>
						<Box
							justifyContent="flex-end"
							sx={{
								flexGrow: 1,
								display: { xs: 'flex', lg: 'none' },
							}}
						>
							<IconButton
								aria-label="open drawer"
								color="inherit"
								data-testid="toggle-drawer"
								edge="start"
								onClick={handleDrawerToggle}
								sx={{ display: { lg: 'none' } }}
							>
								<MenuIcon />
							</IconButton>
						</Box>
					</Toolbar>
				</Container>
			</AppBar>
			<nav>
				<Drawer
					anchor="right"
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					SlideProps={{
						direction: 'left',
					}}
					sx={styles.drawer}
				>
					<Box
						sx={{
							flexGrow: 0,
							display: { xs: 'flex', lg: 'none' },
						}}
					>
						<IconButton
							aria-label="open drawer"
							edge="start"
							onClick={handleDrawerToggle}
							sx={styles.drawerCloseButton}
						>
							<CloseSharpIcon />
						</IconButton>
					</Box>
					<Box
						display="flex"
						flexGrow={3}
						onClick={handleDrawerToggle}
						flexDirection={'column'}
						sx={{
							padding: '0 40px 0 0',
						}}
					>
						<List sx={styles.navListMobile}>
							{generateSiteLinks(
								theme,
								[...games, PROFILE_PAGE],
								pathname,
								handleProtectedPathClick,
								true
							)}
						</List>
						<Divider sx={{ borderColor: '#FFFFFF' }}></Divider>
						<List sx={styles.navListMobile}>
							{loginOrSalutationsButton(
								isAuthenticated,
								user?.name ?? '',
								openLoginModal,
								true,
								handleShowUserOptions
							)}
							{isAuthenticated && (
								<ListItem
									data-testid={`${TEST_ID}-logout-primary-button`}
									onClick={() => {
										!isAuthenticated && openLoginModal()
									}}
									sx={{ padding: '12px 0  12px 0' }}
								>
									<ListItemButton onClick={handleLogout}>
										<Typography>
											<ListItemText primary="Logout" />
										</Typography>
									</ListItemButton>
								</ListItem>
							)}
						</List>
						<Divider sx={{ borderColor: '#FFFFFF' }}></Divider>
						<List sx={styles.navListMobile} className="border">
							<ListItem sx={{ padding: 0 }}>
								<ListItemButton
									href="https://go.slalom.com/brainstorm"
									sx={{ textDecoration: 'none' }}
								>
									<Typography>
										<ListItemText primary="Contact Us" />
									</Typography>
								</ListItemButton>
							</ListItem>
						</List>
					</Box>
				</Drawer>
			</nav>
		</Grid>
	)
}

export default ArcNavBar
