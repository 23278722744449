import { useOAuth2 } from '@tasoskakour/react-use-oauth2'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { Box, CircularProgress } from '@mui/material'
import axios from 'axios'
import { HTMLToJSON } from 'html-to-json-parser'
import { JSONContent } from 'html-to-json-parser/dist/types'
import { getSignedRequest } from 'utils/authUtils'

import { SubmittedIdeaCSV } from 'models/ideaModels'

import { useMixpanelContext } from 'contexts/MixpanelProvider'
import { MODAL_TYPES, useModalContext } from 'contexts/ModalProvider'
import { useToastContext } from 'contexts/ToastProvider'

import {
	downloadToMiro,
	exportIdeas,
	parseJSONToCSV,
	removeHTMLFormatting,
	splitIdeasIntoSeparateRows,
} from 'pages/SubmittedIdeas/v1/SubmittedIdeasUtils'

import SplitButtonComponent from 'components/SplitButton'

import { MIXPANEL_EVENTS } from 'enums/MixpanelEvents'
import { ToastSeverity } from 'enums/ToastSeverityEnum'

import { styles } from './ExportButton.styles'

export const TEST_ID = 'submitted-ideas'

interface ExportButtonProps {
	data: SubmittedIdeaCSV[]
}

type miroResponse = {
	data: {
		access_token: string
		expires_in: number
		refresh_token: string
		scope: string
		team_id: string
		token_type: string
		user_id: string
	}
	status: number
}

export default function ExportButton({ data }: ExportButtonProps) {
	const [transformedIdeas, setTransformedIdeas] = useState<
		SubmittedIdeaCSV[]
	>([])
	const [miroExporting, setMiroExporting] = useState(false)
	const { showAlert } = useToastContext()
	const { showModal } = useModalContext()
	const mixpanel = useMixpanelContext()

	const showMiroModal = useCallback(
		(viewLink: string) => {
			showModal(MODAL_TYPES.MIRO_REDIRECT_MODAL, { viewLink })
		},
		[showModal]
	)

	const showAuthAlert = () => {
		if (!showAlert) return
		showAlert({
			title: 'Error',
			severity: ToastSeverity.ERROR,
			bodyText:
				'An error occurred when authorizing to Miro. Please try again',
		})
	}

	const { getAuth } = useOAuth2({
		authorizeUrl: 'https://miro.com/oauth/authorize',
		clientId: process.env.REACT_APP_MIRO_CLIENT_ID || '',
		redirectUri: `${document.location.origin}/callback`,
		scope: 'YOUR_SCOPES',
		responseType: 'code',
		exchangeCodeForTokenQueryFn: async (callbackParameters) => {
			// create the query string
			const qs = `code=${callbackParameters.code}&client_id=${callbackParameters.client_id}&redirect_uri=${document.location.origin}/callback`

			// create an axios request with the required data
			const signed = await getSignedRequest(
				'POST',
				'miroAuth/token?' + qs
			)

			if (signed) {
				const response: miroResponse = await axios({
					...signed,
					url:
						(process.env.REACT_APP_AWS_API_URL as string) +
						'miroAuth/token?' +
						qs,
					method: 'POST',
				})

				if (response.status !== 200) throw new Error('Failed')
				return response.data
			}

			throw Error('Unable to get signed request')
		},
		onError: () => showAuthAlert(),
		onSuccess: (successData) =>
			downloadToMiro(
				successData.access_token,
				setMiroExporting,
				headers,
				transformedIdeas,
				showAlert,
				showMiroModal
			),
	})

	useEffect(() => {
		const transformIdeas = async (ideas: SubmittedIdeaCSV[]) => {
			const newIdeas = ideas.map(async (idea) => {
				const ideationJson = await HTMLToJSON(
					`<div>${removeHTMLFormatting(idea.authorIdeation)}</div>`
				)
				// We want to keep the bolded prompts only for Battle Ships
				const skipBolds = idea.gameType !== 'Battle Ships'
				const csvContent = parseJSONToCSV(
					ideationJson as JSONContent,
					skipBolds
				)

				return {
					...idea,
					authorIdeation: csvContent,
				}
			})

			try {
				const newIdeasResolved = await Promise.all(newIdeas)
				setTransformedIdeas(
					splitIdeasIntoSeparateRows(newIdeasResolved)
				)
			} catch (error) {
				console.error('Error resolving submitted ideas Promise:', error)
			}
		}

		transformIdeas(data)
	}, [data])

	const headers = useMemo(() => {
		if (data?.length) {
			// add 'promptTitle' header to existing headers
			const initialHeaders = Object.keys(data[0])
			const ideationIndex = initialHeaders.indexOf('authorIdeation')
			const promptTitleIndex = initialHeaders.indexOf('promptTitle')
			if (promptTitleIndex > -1) {
				// remove 'promptTitle' if it already exists
				initialHeaders.splice(promptTitleIndex, 1)
			}
			initialHeaders.splice(ideationIndex, 0, 'promptTitle')
			return initialHeaders
		}
		return []
	}, [data])

	const exportDisabled = useMemo(() => {
		let disabled = false
		if (!data?.length) {
			disabled = true
		}
		return disabled
	}, [data])

	const exportRequest = (itemIndex: number) => {
		mixpanel?.track(MIXPANEL_EVENTS.EXPORT_IDEAS, {
			count: transformedIdeas?.length,
		})
		exportIdeas(itemIndex, headers, transformedIdeas, getAuth)
	}

	return (
		<>
			{miroExporting ? (
				<CircularProgress />
			) : (
				<Box
					data-testid={`${TEST_ID}-button-container`}
					sx={styles.csvLinkStyle}
				>
					<SplitButtonComponent
						text={'Export Ideas'}
						itemList={['.CSV file', 'Miro board']}
						defaultItemIndex={0}
						onClickAction={exportRequest}
						disabled={exportDisabled}
					/>
				</Box>
			)}
		</>
	)
}
