// ***************************************************
// Configure Feature Flags HERE - Per Environment

export enum FEATURE_FLAGS {
	SHOW_REDESIGNED_GAMEPAGES = 'show-redesigned-gamepages',
}

const localFlags = {
	[FEATURE_FLAGS.SHOW_REDESIGNED_GAMEPAGES]: true,
}

const devFlags = {
	[FEATURE_FLAGS.SHOW_REDESIGNED_GAMEPAGES]: true,
}

const prodFlags = {
	[FEATURE_FLAGS.SHOW_REDESIGNED_GAMEPAGES]: true,
}

let features: Record<string, boolean> = {}

if (process.env.NODE_ENV === 'development') {
	// Check for local env
	features = localFlags
} else if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
	// REACT_APP_ENVIRONMENT is currently being set to 'dev' or 'main' by terraform in the ideation-infra repo
	features = devFlags
} else {
	features = prodFlags
}

export default features
